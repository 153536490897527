@use "sass:math";

$primary: #3cb7d7;
$secondary: #3296af;
$success: #48bb78;
$info: #f0eff0;
$warning: #ff9f1a;
$danger: #ef6360;
$light: #edf8fb;
$dark: #1a91d9;
$light-blue: #18296b;
$shadowSilver: "rgba(0, 0, 0, 0.16)";
$borderLightSilver: "#e8e8e8";
$fontSilverDark: "#4a4a4a";
$text-muted: #99999b;
$bg-color: #edf2f7;
$bg-secondary: #fbfbfb;
$white: #ffffff;
$input-placeholder-color: #e3e6eb;
$dark-blue: #0c1d42;
$gray: #e0e0e0;

// $header-bg: #b6cacd;
// $sider-bg: #00636b;

$header-bg: $white;
$sider-bg: #3cb7d7;

$input-bg: $bg-secondary;
$MAX_INPUT_WIDTH: "345px";

$base_px: 16;
$toRems: math.div(1, $base_px) + 0rem;

$font-path: "fonts";

$font-size-base: 16;
$font-size-normal: 14px;
$font-size-sm: 10 * $toRems;

$lead-font-size: 16 * $toRems;
$small-font-size: 12 * $toRems;

$headings-font-weight: normal;
$h1-font-size: 22 * $toRems;
$h2-font-size: 18 * $toRems;
$font-size-large: 24px;

$icomoon-font-path: $font-path;
$icomoon-font-family: "b2c";
$icon-user-cog: "\e900";
$icon-user: "\e901";
$icon-chart-pie: "\e902";
$icon-settings: "\e903";
$icon-binoculars: "\e904";
$icon-user-circle: "\e905";
$icon-bell: "\e906";
$icon-sign-out: "\e907";
$icon-chevron-right-solid: "\e908";
$icon-search-solid: "\e909";
$icon-users: "\e90a";
$icon-plus-solid: "\e90b";
$icon-eye-solid: "\e90d";
$icon-window-close-solid: "\e90c";
$icon-file-medical-solid: "\e90e";
$icon-file-solid: "\e90f";
$icon-download-solid: "\e910";
$icon-trash-alt-solid: "\e911";
$icon-ellipsis-v-solid: "\e912";
$icon-image-solid: "\e913";
$icon-arrow-left-solid: "\e914";
$icon-view-sidebar: "\e915";
$icon-assessment: "\e916";
$icon-admin-settings: "\e917";
$icon-backup: "\e918";
$icon-handyman: "\e919";
$icon-link: "\e91a";
