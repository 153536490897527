@import "variables";

@font-face {
  font-family: "#{$icomoon-font-family}";

  src: url("../fonts/b2c.eot?up6h3m#iefix") format("embedded-opentype"),
    url("../fonts/b2c.ttf?up6h3m") format("truetype"), url("../fonts/b2c.woff?up6h3m") format("woff"),
    url("../fonts/b2c.svg?up6h3m##{$icomoon-font-family}") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i,
.icomoon-liga {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-user-cog-solid {
  &:before {
    content: $icon-user-cog;
  }
}
.icon-user-solid {
  &:before {
    content: $icon-user;
  }
}
.icon-audiences {
  &:before {
    content: $icon-chart-pie;
  }
}
.icon-configure {
  &:before {
    content: $icon-settings;
  }
}
.icon-monitor {
  &:before {
    content: $icon-binoculars;
  }
}
.icon-user {
  &:before {
    content: $icon-user-circle;
  }
}
.icon-bell {
  &:before {
    content: $icon-bell;
  }
}
.icon-signout {
  &:before {
    content: $icon-sign-out;
  }
}
.icon-stepper {
  &:before {
    content: $icon-chevron-right-solid;
  }
}
.icon-search {
  &:before {
    content: $icon-search-solid;
  }
}
.icon-users {
  &:before {
    content: $icon-users;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus-solid;
  }
}
.icon-view {
  &:before {
    content: $icon-eye-solid;
  }
}
.icon-close {
  &:before {
    content: $icon-window-close-solid;
  }
}
.icon-view-report {
  &:before {
    content: $icon-file-medical-solid;
  }
}
.icon-file {
  &:before {
    content: $icon-file-solid;
  }
}
.icon-download {
  &:before {
    content: $icon-download-solid;
  }
}
.icon-trash {
  &:before {
    content: $icon-trash-alt-solid;
  }
}
.icon-more-info {
  &:before {
    content: $icon-ellipsis-v-solid;
  }
}
.icon-image {
  &:before {
    content: $icon-image-solid;
  }
}
.icon-left-arrow {
  &:before {
    content: $icon-arrow-left-solid;
  }
}
.icon-view-sidebar {
  &:before {
    content: $icon-view-sidebar;
  }
}
.icon-assessment {
  &:before {
    content: $icon-assessment;
  }
}
.icon-admin-settings {
  &:before {
    content: $icon-admin-settings;
  }
}
.icon-backup {
  &:before {
    content: $icon-backup;
  }
}
.icon-handyman {
  &:before {
    content: $icon-handyman;
  }
}
.icon-link {
  &:before {
    content: $icon-link;
  }
}
