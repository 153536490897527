a {
  cursor: pointer;
}
i {
  cursor: pointer;
}
html {
  margin: 0;
  height: 100%;
  overflow: hidden;
}
body {
  background-color: #e5e5e5;
}
.mt-10 {
  margin-top: 10px !important;
}

// BS Buttons

$btn-font-size-lg: 15px;
$btn-padding-x-lg: 19px;
$btn-padding-y-lg: 16px;
$btn-border-radius: 2px;

.btn-light {
  border-color: $primary;
  color: $header-bg;
  font-size: 15px;
  &:hover {
    background-color: $primary;
    color: $white;
  }
}
.btn-login {
  background-color: $warning;
  color: #000;
  font-size: 18px;
  font-weight: bold;
  width: inherit;
  text-align: center;
  border-radius: 9px;
}
.btn_audience {
  background-color: $warning;
  color: #000;
  font-size: 18px;
  font-weight: bold;
  height: 35px;
  &:hover {
    background-color: $warning;
    color: #000;
  }
}
div.noPadding {
  padding-left: 0;
  padding-right: 0;
}

.inline-box {
  display: -webkit-inline-box;
}
